
<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.dviewcontent.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-autocomplete
            v-model="dviewcontentID"
            :items="dviewcontentWorkspaces"
            :loading="loading"
            outlined
            dense
            item-text="name"
            item-value="id"
            :label="$t('booking.dviewcontent.id', locale)"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-checkbox
            :label="$t('booking.allowReservation', locale)"
            v-model="dviewcontentAllowReservation"
            disabled
            hide-details
            @change="handleChangeData"
            style="margin-top: 0;"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <color-picker-item
            :value="dviewcontentColor"
            :label="$t('common.color', locale)"
            :onChange="handleChangeColor"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'DviewcontentForm',
  components: {
    ColorPickerItem
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    dviewcontentID: null,
    dviewcontentAllowReservation: false,
    dviewcontentColor: 'green',
    dviewcontentWorkspaces: [],
    loading: true
  }),
  methods: {
    getWorkspaces () {
      api.getAllWithoutLimit('booking', 'v1/system/dviewcontent')
        .then(response => {
          this.dviewcontentWorkspaces = [{
            id: null,
            name: '',
          }]
          this.dviewcontentWorkspaces.push(...response.map(item => {
            return ({
              id: item.ID,
              name: item.Name
            })
          }))
          this.loading = false
      })
    },
    getData () {
      api.getItem('booking', `v1/system/workspaces/`, `${this.workspaceID}/dviewcontent`)
        .then(response => {
          this.dviewcontentAllowReservation = response && response.DviewcontentAllowReservation ? response.DviewcontentAllowReservation : false
          this.dviewcontentID = response && response.DviewcontentID ? response.DviewcontentID : null
          this.dviewcontentColor = response && response.DviewcontentColor ? response.DviewcontentColor: 'green'
        })
    },
    handleExpand () {
      this.getWorkspaces()
      if(!this.dviewcontentID && this.workspaceID) this.getData()
    },
    handleChangeData () {
      this.onChangeData({
        dviewcontentID: this.dviewcontentID,
        dviewcontentAllowReservation: this.dviewcontentAllowReservation,
        dviewcontentColor: this.dviewcontentColor,
      })
    },
    handleChangeColor (v) {
      this.dviewcontentColor = v
      this.handleChangeData ()
    },
  },
}
</script>

